import { plantasOptions } from '@auth/utils/register'
import { Control } from '@shared/features/forms/models/forms.model'

export const signUpControls: Control[] = [
  {
    label: 'Nombre completo',
    name: 'nombre-completo',
    type: 'text',
    controlType: 'input',
    value: '',
    order: 0,
    validators: {
      required: true,
      minLength: 4
    }
  },
  {
    label: 'Correo Electrónico',
    name: 'email',
    type: 'email',
    controlType: 'input',
    helpText: 'Debes tener un correo de ab-inbev',
    value: '',
    order: 1,
    validators: {
      required: true,
      email: true
    }
  },
  {
    label: 'Contraseña',
    name: 'password',
    type: 'password',
    controlType: 'input',
    value: '',
    order: 2,
    validators: {
      required: true
    }
  },
  {
    label: 'Confirmar contraseña',
    name: 'password_confirm',
    type: 'password',
    controlType: 'input',
    value: '',
    order: 3,
    validators: {
      required: true
    }
  },
  {
    label: 'Cargo',
    name: 'cargo',
    type: 'text',
    controlType: 'input',
    value: '',
    order: 4,
    validators: {
      required: true
    }
  },
  {
    label: 'Celular',
    name: 'celular',
    type: 'number',
    controlType: 'phone',
    value: '',
    order: 5,
    validators: {
      required: true
    }
  },
  {
    label: 'Eres PPM',
    name: 'eres_ppm',
    controlType: 'select',
    value: '',
    order: 6,
    options: [
      { label: '::Seleccionar::', value: '', selected: true, disabled: true },
      { label: 'Si', value: 'si' },
      { label: 'No', value: 'no' }
    ],
    validators: {
      required: true
    }
  },
  {
    label: 'VP',
    name: 'vp',
    controlType: 'select',
    value: '',
    order: 7,
    options: [
      { label: '::Seleccionar::', value: '', selected: true, disabled: true },
      { label: 'Supply', value: 'Supply' },
      { label: 'Procurement', value: 'Procurement' },
      { label: 'Logística', value: 'logistica' },
      { label: 'Otro', value: 'otro' }
    ],
    validators: {
      required: true
    }
  },
  {
    label: 'Area / Categoría',
    name: 'area-categoria',
    controlType: 'input',
    value: '',
    order: 8,
    visible: form => {
      const values = ['Procurement', 'logistica', 'otro']
      return values.includes(form.controls['vp']?.value)
    },
    validators: {
      required: true
    }
  },
  {
    label: 'BU',
    name: 'select-bu',
    controlType: 'select',
    value: '',
    order: 9,
    visible: form => {
      return form.controls['vp']?.value === 'Supply'
    },
    options: [
      { label: '::Seleccionar::', value: '', selected: true, disabled: true },
      { label: 'ZONA', value: 'zona' },
      { label: 'COL', value: 'COL' },
      { label: 'PEC', value: 'PEC' },
      { label: 'MEX NORTE', value: 'MEX Norte' },
      { label: 'MEX SUR', value: 'MEX Sur' },
      { label: 'CAC', value: 'CAC' },
      { label: 'HES', value: 'HES' },
      { label: 'Supply Trainee', value: 'Supply Trainee' },
      { label: 'Vertical Operations', value: 'Vertical Operations' }
    ],
    validators: {
      required: true
    }
  },
  {
    label: 'Plantas',
    name: 'plantas',
    controlType: 'select',
    value: '',
    order: 10,
    visible: form => {
      const values = ['COL', 'PEC', 'MEX Norte', 'MEX Sur', 'CAC', 'HES', 'Supply Trainee', 'Vertical Operations']
      return values.includes(form.controls['select-bu']?.value)
    },
    asyncOptions: form => {
      return plantasOptions(form)
    },
    validators: {
      required: true
    }
  },
  {
    label: 'Area',
    name: 'area',
    controlType: 'select',
    value: '',
    order: 11,
    visible: form => {
      return form.controls['select-bu']?.value === 'zona'
    },
    options: [
      { label: '::Seleccionar::', value: '', selected: true, disabled: true },
      { label: 'PPM', value: 'PPM' },
      { label: 'B&Q', value: 'B&Q' },
      { label: 'ZBS', value: 'ZBS' },
      { label: 'ZITEC', value: 'ZITEC' },
      { label: 'People', value: 'People' },
      { label: 'Otro', value: 'Otro' }
    ],
    validators: {
      required: true
    }
  },
  {
    label: 'ZBS',
    name: 'zbs',
    controlType: 'select',
    value: '',
    order: 12,
    visible: form => {
      return form.controls['area']?.value === 'ZBS'
    },
    options: [
      { label: '::Seleccionar::', value: '', selected: true, disabled: true },
      { label: 'VPO', value: 'VPO' },
      { label: 'Safety', value: 'Safety' },
      { label: 'Environment', value: 'Environment' },
      { label: 'E&F', value: 'E&F' },
      { label: 'Proyectos', value: 'Proyectos' },
      { label: 'Innovation', value: 'Innovation' },
      { label: 'Tech Supply', value: 'Tech Supply' },
      { label: 'Maintenance', value: 'Maintenance' },
      { label: 'Packaging', value: 'Packaging' },
      { label: 'People', value: 'People' },
      { label: 'VOPS', value: 'VOPS' }
    ],
    validators: {
      required: true
    }
  },
  {
    label:
      'He leído y acepto los <a href="https://superlikers-themes.s3.amazonaws.com/ppmu/T%26C_PPMU.docx.pdf" target="_blank">Términos y Condiciones</a> y <a href="https://superlikers-themes.s3.amazonaws.com/ppmu/POLITICAS+DE+PRIVACIDAD+PPMU.pdf" target="_blank">Políticas de privacidad</a>',
    name: 'he-leido-y-acepto-los-terminos-y-condiciones-y-politicas-de-privacidad',
    controlType: 'checkbox',
    value: false,
    order: 13,
    validators: {
      requiredTrue: true
    }
  }
]
